
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: "user",
      title: "標籤",
      multiselect: false,
      mode: 'inline',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "標籤清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "標籤清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: "包含" }], slots: { filter: "filter-name" },
          editRender: { name: '$input', immediate: true, props: { type: 'text' } }
        },
        {
          field: "Ordinal", title: '排序', align: "center", showHeaderOverflow: true, showOverflow: true, sortable: true, width: 80,
          editRender: { name: '$input', immediate: true, props: { type: 'number' } }
        },
      ],
      promises: {
        query: model ? (params) => model.dispatch("tag/query", params) : undefined,
        queryAll: model ? () => model.dispatch("tag/query") : undefined,
        save: model ? (params) => model.dispatch("tag/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Name", title: "名稱", span: 24, itemRender: { name: "$input", props: { placeholder: "請輸入名稱" } } },
        { field: "Ordinal", title: "排序", span: 24, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入數值" } } },
      ],
      rules: {
        Name: [{ required: true }],
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
    };
  },
  methods: {
    hideBatchDropDown () {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
